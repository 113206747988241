
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

import { Box, IconButton, Typography, useTheme } from "@mui/material";
import {useState} from "react";
import { Menu, MenuItem, ProSidebar } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import ReceiptIcon from "@mui/icons-material/Receipt";
import logo from '../../assets/summit logo.png'

const Item = ({ title, to, icon, selected, setSelected, children }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  const handleSubmenuToggle = () => {
    setIsSubmenuOpen(!isSubmenuOpen);
  };

  return (
    <>
      <MenuItem
        active={selected === title}
        style={{
          color: colors.grey[100],
          backgroundColor: selected === title ? "#868dfb" : "transparent",
          "&:hover": {
            backgroundColor: "#868dfb",
          },
        }}
        onClick={() => {
          setSelected(title);
          handleSubmenuToggle();
        }}
        icon={icon}
      >
        <Typography>{title}</Typography>
        <Link to={to} />
      </MenuItem>
      {isSubmenuOpen && children}
    </>
  );
};

const Subtopic = ({ title, to, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
        textAlign: "center",
        backgroundColor: selected === title ? "#868dfb" : "transparent",
        "&:hover": {
          backgroundColor: "#868dfb",
        },
      }}
      onClick={() => setSelected(title)}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "10px 30px 5px 10px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "30px 20 20px 20px",
              color: colors.grey[100],
              textAlign: "center",
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="30px"
              >
                <img
                  alt="profile-user"
                  src={logo}
                  style={{
                    width: "45%",
                    height: "45%",
                    cursor: "pointer",
                    // borderRadius: "50%",
                    transform:
                      theme.palette.mode === "dark" ? "scale(1.2)" : "scale(1)",
                    transition: "transform 0.3s ease",
                  }}
                  justifyContent="center"
                />
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          <Box
            pl={!isCollapsed ? "20px" : undefined}
            alignItems="left"
            sx={{ marginTop: "20px" }}
            width={!isCollapsed ? "calc(100% + 40px)" : undefined}
          >
            
            {
              <Item
                title="Agents"
                to="/agents"
                icon={<ReceiptIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            }

            <Item
              title="Listings"
              to="/listings"
              icon={<ReceiptIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
