import React, { useState } from 'react';
import { 
  Button, 
  TextField, 
  Typography, 
  Snackbar, 
  InputAdornment, 
  IconButton 
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";
import { environment } from "../../environment";
import background from "../../assets/summit full logo.jpg";
import logo from "../../assets/summit logo.png";
import { useNavigate } from 'react-router-dom';
import "./login.css";

function ForgotPassword() {
  const [stage, setStage] = useState('email');
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const navigate = useNavigate()

  const handleSendOtp = async (e) => {
    e.preventDefault();
    if (!email) {
      setError('Please enter an email address');
      return;
    }
    try {
      const res = await axios.post(environment.apiUrl + "/admin/sendOTP", { email });
      if (res.data.success) {
        setStage('otp');
        setAlertSeverity("success");
        setAlertMessage("OTP sent successfully!");
        setOpenSnackbar(true);
      }
    } catch (err) {
      setAlertSeverity("error");
      setAlertMessage(`Failed to send OTP: ${err.message}`);
      setOpenSnackbar(true);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    if (!otp) {
      setError('Please enter the OTP');
      return;
    }
    try {
      const res = await axios.post(environment.apiUrl + "/admin/validateOtp", { enteredOTP:otp, email });
      if (res.data.success) {
        setStage('reset');
        setAlertSeverity("success");
        setAlertMessage("OTP verified successfully!");
        setOpenSnackbar(true);
      }
    } catch (err) {
        console.log("Error ocurred",err);
      setAlertSeverity("error");
      setAlertMessage(`Failed to verify OTP: ${err.message}`);
      setOpenSnackbar(true);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (!newPassword || !confirmPassword) {
      setError('Please enter and confirm your new password');
      return;
    }
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    try {
      const res = await axios.put(environment.apiUrl + "/admin/ChangePasswordWithEmail", { email, newPassword });
      if (res.data.success) {
        setAlertSeverity("success");
        setAlertMessage("Password reset successfully!");
        setOpenSnackbar(true);
        navigate('/')
      }
    } catch (err) {
        console.log("error",err)
      setAlertSeverity("error");
      setAlertMessage(`Failed to reset password: ${err.message}`);
      setOpenSnackbar(true);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="div-container h-100">
      <div className="row h-100">
        <div className="col-md-5 col-sm-12 d-flex align-items-center justify-content-center">
          <div className="forgot-password-container" style={{ width: '80%' }}>
            <h2>Forgot Password</h2>
            <Typography component="p" variant="p" className="mt-4">
              {stage === 'email' && "Enter your email to receive OTP"}
              {stage === 'otp' && "Enter the OTP sent to your email"}
              {stage === 'reset' && "Reset your password"}
            </Typography>
            {stage === 'email' && (
              <form onSubmit={handleSendOtp}>
                <Typography component="p" variant="p" className="mt-4">
                  *Email
                </Typography>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={!!error}
                  helperText={error}
                  className="mt-0"
                />
                <div className="d-flex align-items-center justify-content-center">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className="mt-5 submit-button"
                  >
                    Send OTP
                  </Button>
                </div>
              </form>
            )}
            {stage === 'otp' && (
              <form onSubmit={handleVerifyOtp}>
                <Typography component="p" variant="p" className="mt-4">
                  *OTP
                </Typography>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="otp"
                  name="otp"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  error={!!error}
                  helperText={error}
                  className="mt-0"
                />
                <div className="d-flex align-items-center justify-content-center">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className="mt-5 submit-button"
                  >
                    Verify OTP
                  </Button>
                </div>
              </form>
            )}
            {stage === 'reset' && (
              <form onSubmit={handleResetPassword}>
                <Typography component="p" variant="p" className="mt-4">
                  *New Password
                </Typography>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="newPassword"
                  type={showPassword ? "text" : "password"}
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  error={!!error}
                  helperText={error}
                  className="mt-0"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Typography component="p" variant="p" className="mt-2">
                  *Confirm New Password
                </Typography>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="confirmPassword"
                  type={showPassword ? "text" : "password"}
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  error={!!error}
                  helperText={error}
                  className="mt-0"
                />
                <div className="d-flex align-items-center justify-content-center">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className="mt-5 submit-button"
                  >
                    Reset Password
                  </Button>
                </div>
              </form>
            )}
          </div>
        </div>
        <div className="col-md-7 p-0 d-none d-md-block">
          <img src={background} alt="background" className="b-img h-100 w-100 object-fit-cover" />
        </div>
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

export default ForgotPassword;