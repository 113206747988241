import { CssBaseline, ThemeProvider } from "@mui/material";
import { useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Sidebar from "./scenes/global/Sidebar";
import Topbar from "./scenes/global/Topbar";
import Login from "./scenes/login";
import { ColorModeContext, useMode } from "./theme";
import PrivateRoute from "./PrivateRoutes.js";
import AgentsTab from "./scenes/agents/agents.jsx";
import Listings from "./scenes/listings/index.jsx";
import AddListing from "./scenes/listings/AddListing.jsx";
import ViewListing from "./scenes/listings/ViewLisitng.jsx";
import UserProfileDetails from "./scenes/agents/userProfileDetails.jsx";
import AgentForm from "./scenes/agents/agentForm.jsx";
import AdminProfile from "./components/AdminProfile.jsx";
import ForgotPassword from "./scenes/login/ForgotPassword.jsx";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation();

  // Check if the current route is the login page
  const isLoginPage = location.pathname === "/" || location.pathname === "/forgotPassword" ;

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {!isLoginPage && <Sidebar isSidebar={isSidebar} />}
          <main className="content">
            {!isLoginPage && <Topbar setIsSidebar={setIsSidebar} />}
            <Routes>
              <Route path="/" element={<Login />} />

              <Route path="/forgotPassword" element={<ForgotPassword />} />

              <Route
                path="/profile"
                element={
                  <PrivateRoute>
                    <AdminProfile />
                  </PrivateRoute>
                }
              />
              

              <Route  path="/agents" element={<PrivateRoute><AgentsTab /></PrivateRoute>} />
              <Route path="/agents/create" element={<PrivateRoute><AgentForm /></PrivateRoute>} />
              <Route path="/agents/:id" element={<PrivateRoute><UserProfileDetails /></PrivateRoute>} />
              <Route
                path="/listings"
                element={
                  <PrivateRoute>
                    <Listings />
                  </PrivateRoute>
                }
              ></Route>

              <Route
                path="/listings/newListing"
                element={
                  <PrivateRoute>
                    <AddListing />
                  </PrivateRoute>
                }
              ></Route>

              <Route
                path="/listings/editListing/:id"
                element={
                  <PrivateRoute>
                    <AddListing />
                  </PrivateRoute>
                }
              />
              <Route
                path="/listings/viewListing/:id"
                element={
                  <PrivateRoute>
                    <ViewListing />
                  </PrivateRoute>
                }
              />

              <Route
                path="/listings"
                element={
                  <PrivateRoute>
                    <Listings />
                  </PrivateRoute>
                }
              ></Route>

              <Route
                path="/listings/newListing"
                element={
                  <PrivateRoute>
                    <AddListing />
                  </PrivateRoute>
                }
              ></Route>

              <Route
                path="/listings/editListing/:id"
                element={
                  <PrivateRoute>
                    <AddListing />
                  </PrivateRoute>
                }
              />
              <Route
                path="/listings/viewListing/:id"
                element={
                  <PrivateRoute>
                    <ViewListing />
                  </PrivateRoute>
                }
              />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
