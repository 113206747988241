import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  useTheme,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import axios from "axios";
import * as yup from "yup";
import { environment } from "../../environment";
import Loading from "../../components/Loading";
import SweetAlert from "../../components/SweetAlert";
import { Edit } from "@mui/icons-material";
import Swal from "sweetalert2";

const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
});

const AgentForm = () => {
  const token = localStorage.getItem("token");
  const formData = new FormData();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = useState(false);
  const [agent, setAgent] = useState({
    id: "",
    fName: "",
    lName: "",
    email: "",
    officePhone: "",
    cellPhone: "",
    profileImage: "",
    // listings: [],
    language: "",
  });

  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (id && location.state && location.state.agent) {
      setAgent(location.state.agent);
    } else if (id) {
      setAgent((prevAgent) => ({ ...prevAgent, id }));
    }
  }, [id, location.state]);

  useEffect(() => {
    validateForm();
  }, [agent.fName, agent.email]);

  const validateForm = async () => {
    try {
      await validationSchema.validate(
        { name: agent.fName, email: agent.email },
        { abortEarly: false }
      );
      setErrors({});
      setIsValid(true);
    } catch (yupError) {
      const newErrors = {};
      yupError.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      setErrors(newErrors);
      setIsValid(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAgent((prevAgent) => ({
      ...prevAgent,
      [name]: value,
    }));
  };

  const handleListingChange = (index, field, value) => {
    const updatedListings = [...agent.listings];
    updatedListings[index] = { ...updatedListings[index], [field]: value };
    setAgent((prevAgent) => ({
      ...prevAgent,
      listings: updatedListings,
    }));
  };

  const addListing = () => {
    setAgent((prevAgent) => ({
      ...prevAgent,
      listings: [
        ...prevAgent.listings,
        { id: "", city: "", area: "", zip: "" },
      ],
    }));
  };

  const removeListing = (index) => {
    setAgent((prevAgent) => ({
      ...prevAgent,
      listings: prevAgent.listings.filter((_, i) => i !== index),
    }));
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      Swal.fire({
        title: "Error",
        text: "File upload failed",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      return;
    }

    setUploading(true);

    try {
      // console.log(base64String);
      // setAgent((prevAgent) => ({
      //   ...prevAgent,
      //   profileImage: file, // Storing base64 in the profileImage
      // }));

      formData.append("imageURL", file);
    } catch (error) {
      console.error("Error uploading image: ", error);
      // Handle the error appropriately
    } finally {
      setUploading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (isValid) {
      try {
        // Create FormData object

        formData.append("firstName", agent.fName);
        formData.append("lastName", agent.lName);
        formData.append("email", agent.email);
        formData.append("officePhone", agent.officePhone);
        formData.append("personalPhone", agent.personalPhone);
        formData.append("language", agent.language);

        // Check if the profileImage is a file or already base64
        if (agent.profileImage && agent.profileImage instanceof File) {
          formData.append("profileImage", agent.profileImage);
        } else if (agent.profileImage) {
          // Convert base64 string to a Blob object
          const byteString = atob(agent.profileImage.split(",")[1]);
          const mimeString = agent.profileImage
            .split(",")[0]
            .split(":")[1]
            .split(";")[0];
          const arrayBuffer = new ArrayBuffer(byteString.length);
          const intArray = new Uint8Array(arrayBuffer);
          for (let i = 0; i < byteString.length; i++) {
            intArray[i] = byteString.charCodeAt(i);
          }
          const blob = new Blob([intArray], { type: mimeString });
          formData.append("profileImage", blob, "profileImage.png");
        }

        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        };

        const res = await axios.post(
          environment.apiUrl + "/landAgent/landAgentAdd",
          formData,
          { headers }
        );

        if (res.status === 200) {
          Swal.fire({
            title: "Success",
            text: "Agent has been created successfully",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          });
          setAgent({
            id: "",
            fName: "",
            lName: "",
            email: "",
            officePhone: "",
            cellPhone: "",
            profileImage: "",
            language: "",
          });
        }
        console.log(res);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Box m="20px">
      <Header
        title={id ? "Edit Agent" : "Create Agent"}
        subtitle={
          id ? "Modify agent information" : "Add a new agent to the system"
        }
      />
      <form onSubmit={handleSubmit}>
        <Box
          display="grid"
          gap="30px"
          gridTemplateColumns="repeat(2, 1fr)"
          sx={{
            "& > div": { gridColumn: "span 1" },
          }}
        >
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="First Name"
            name="fName"
            value={agent.fName}
            onChange={handleChange}
            error={!!errors.name}
            helperText={errors.name}
          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Last Name"
            name="lName"
            value={agent.lName}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            variant="filled"
            type="email"
            label="Email"
            name="email"
            value={agent.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Office Phone"
            name="officePhone"
            value={agent.officePhone}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Cell Phone"
            name="cellPhone"
            value={agent.cellPhone}
            onChange={handleChange}
          />

          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Language"
            name="language"
            value={agent.language}
            onChange={handleChange}
          />
          <Box>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="raised-button-file"
              type="file"
              onChange={handleImageUpload}
            />
            <label htmlFor="raised-button-file">
              <Button
                variant="contained"
                component="span"
                startIcon={<CloudUploadIcon />}
                disabled={uploading}
              >
                {uploading ? "Uploading..." : "Upload Profile Image"}
              </Button>
            </label>
            {agent.profileImage && (
              <Box mt={2}>
                <img
                  src={agent.profileImage}
                  alt="Profile"
                  style={{ maxWidth: "100px", maxHeight: "100px" }}
                />
              </Box>
            )}
          </Box>
        </Box>
        {/* 
        <Typography
          variant="h6"
          style={{ marginTop: "20px", marginBottom: "10px" }}
        >
          Listings
        </Typography>
       {agent.listings.map((listing, index) => (
          <Box
            key={index}
            display="grid"
            gap="20px"
            gridTemplateColumns="repeat(2, 1fr)"
            mb="20px"
          >
            <TextField
              variant="filled"
              type="text"
              label="Listing ID"
              value={listing.id}
              onChange={(e) => handleListingChange(index, "id", e.target.value)}
            />
            <TextField
              variant="filled"
              type="text"
              label="City"
              value={listing.city}
              onChange={(e) =>
                handleListingChange(index, "city", e.target.value)
              }
            />
            <TextField
              variant="filled"
              type="text"
              label="Area"
              value={listing.area}
              onChange={(e) =>
                handleListingChange(index, "area", e.target.value)
              }
            />
            <TextField
              variant="filled"
              type="text"
              label="ZIP"
              value={listing.zip}
              onChange={(e) =>
                handleListingChange(index, "zip", e.target.value)
              }
            />
            <Box gridColumn="span 2" display="flex" justifyContent="flex-end">
              <IconButton onClick={() => removeListing(index)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        ))} */}
        {/* <Button
          startIcon={<AddIcon />}
          onClick={addListing}
          variant="outlined"
          style={{ marginBottom: "20px" }}
        >
          Add Listing
        </Button> */}

        <Box display="flex" justifyContent="end" mt="20px">
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            disabled={!isValid || loading}
            onClick={handleSubmit}
          >
            {loading ? "Loading..." : "Add agent"}
          </Button>
        </Box>
      </form>
      {loading && <Loading />}
    </Box>
  );
};

export default AgentForm;
